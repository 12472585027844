import { defineStore } from 'pinia';
import { computed, ref, type Ref } from 'vue';
import type { AuthTokens, ChangePasswordData, Credentials, PasswordResetData, PersonalData, Settings } from '../models';
import { useLocalStorage, useSessionStorage } from '@vueuse/core';
import { useCommonApi } from '../../api';
import { useRouter } from 'vue-router';
import { useNotifications } from '../../notification';
import { Permission, RoleType } from '../enums';
import { useWelcomeModal } from '../composables/welcome-modal.composable';
import { HideWelcomeModal } from '../enums/hide-wecome-modal.enum';
import { useI18nInstance } from '../../i18n';
import { checkPermission, createPermissionsFromRole, getAdminHomeRouteFromRole } from '../utils';
import { isAppName } from '../../../utils';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const REMEMBER_ME_KEY = 'rememberMe';
const IMPERSONATED_KEY = 'impersonated';

export const useAuthStore = defineStore('authStore', () => {
  const commonApi = useCommonApi();
  const router = useRouter();
  const notifications = useNotifications();
  const { openWelcomeModal, getHideWelcomeModal } = useWelcomeModal();
  const i18n = useI18nInstance();

  const rememberMe: Ref<boolean> = useLocalStorage(REMEMBER_ME_KEY, false);
  const sessionAccessToken: Ref<string | null> = useSessionStorage(ACCESS_TOKEN_KEY, null);
  const sessionRefreshToken: Ref<string | null> = useSessionStorage(REFRESH_TOKEN_KEY, null);
  const persistentAccessToken: Ref<string | null> = useLocalStorage(ACCESS_TOKEN_KEY, null);
  const persistentRefreshToken: Ref<string | null> = useLocalStorage(REFRESH_TOKEN_KEY, null);
  const impersonated: Ref<boolean> = useSessionStorage(IMPERSONATED_KEY, false);

  const accessToken = computed(() => (rememberMe.value && !impersonated.value ? persistentAccessToken.value : sessionAccessToken.value));
  const refreshToken = computed(() => (rememberMe.value && !impersonated.value ? persistentRefreshToken.value : sessionRefreshToken.value));
  const isImpersonated = computed(() => impersonated.value);

  const personalData: Ref<PersonalData | null> = ref(null);
  const permissions: Ref<Permission[]> = ref([]);
  const loggedIn = computed(() => !!accessToken.value);
  const loginPromise: Ref<Promise<void> | undefined> = ref();
  const isAdmin = computed(() => personalData.value?.role === RoleType.ADMIN || false);

  const hasAnyModules = computed(
    () => hasPermission(Permission.MODULE_MAP) || hasPermission(Permission.MODULE_PLAN) || hasPermission(Permission.MODULE_MONITORING)
  );

  const isDemoOfferVisible = computed(() => personalData.value?.demoOfferVisible || false);

  const setAuthTokens = (tokens: AuthTokens) => {
    if (rememberMe.value && !impersonated.value) {
      persistentAccessToken.value = tokens.access;
      persistentRefreshToken.value = tokens.refresh;
    } else {
      sessionAccessToken.value = tokens.access;
      sessionRefreshToken.value = tokens.refresh;
    }
  };

  const login = (credentials: Credentials): Promise<void> => {
    loginPromise.value = new Promise<void>((resolve, reject) => {
      loginFn(credentials)
        .then(() => resolve())
        .catch((e) => reject(e))
        .finally(() => (loginPromise.value = undefined));
    });

    return loginPromise.value as Promise<void>;
  };

  const loginFn = async (credentials: Credentials) => {
    const response = await commonApi.auth.login(credentials);

    if (response.data) {
      rememberMe.value = credentials.rememberMe ?? false;
      setAuthTokens(response.data);

      await loadPersonalData();

      if (isAppName('admin')) {
        if (!permissions.value.length) {
          notifications.showError('auth.notification.login.missingPermissions');
          await logout();
          return;
        }

        const homeRoute = router.getRoutes().find((route) => route.name === 'home');
        if (homeRoute) {
          homeRoute.redirect = getAdminHomeRouteFromRole(personalData.value?.role || null);
        }
      }

      const redirect = router.currentRoute.value.query.redirect;
      await router.replace(redirect ? redirect.toString() : { name: 'home' });

      if (isAppName('vantage') && personalData.value?.role === RoleType.CUSTOMER && getHideWelcomeModal.value != HideWelcomeModal.HIDE) {
        openWelcomeModal();
      }
    }
  };

  const loadTranslations = async () => {
    try {
      const response = await commonApi.translation.loadTranslations('hu');
      const backendTranslations = response.data;

      i18n.global.mergeLocaleMessage('hu', backendTranslations);
    } catch {
      console.error('Failed to load backend translations');
    }
  };

  const refreshAccessToken = async () => {
    if (refreshToken.value) {
      const response = await commonApi.auth.refreshToken(refreshToken.value);

      const tokens = response.data;
      if (tokens) {
        setAuthTokens({ refresh: tokens.refresh, access: tokens.access });

        return true;
      }
    }

    return false;
  };

  const setPassword = async (passwordResetData: PasswordResetData) => {
    await commonApi.auth.setPassword(passwordResetData);

    await router.push({ name: 'login' });
  };

  const logout = async (useRedirect?: any) => {
    sessionAccessToken.value = null;
    sessionRefreshToken.value = null;

    if (!impersonated.value) {
      persistentAccessToken.value = null;
      persistentRefreshToken.value = null;
    }

    impersonated.value = false;
    permissions.value = [];

    let redirect: any = { name: 'login' };
    if (useRedirect === true && router.currentRoute.value.name !== 'login') {
      redirect = { name: 'login', query: { redirect: router.currentRoute.value.fullPath } };
    }

    await router.push(redirect);
  };

  const forgotPassword = async (email: string) => {
    await commonApi.auth.forgotPassword(email);
  };

  const changePassword = async (changePasswordData: ChangePasswordData) => {
    const response = await notifications.promise(commonApi.auth.changePassword(changePasswordData), {
      text: 'auth.notification.changePassword.success',
      errorText: 'auth.notification.changePassword.error'
    });

    if (persistentAccessToken.value) {
      rememberMe.value = true;
    }

    setAuthTokens(response.data);
  };

  const savePersonalData = async (data: PersonalData) => {
    const response = await notifications.promise(commonApi.auth.savePersonalData(data), {
      text: 'auth.notification.savePersonalData.success',
      errorText: 'auth.notification.savePersonalData.error'
    });
    if (response.data) {
      personalData.value = response.data;
    }
  };

  const loadPersonalData = async () => {
    const response = await commonApi.auth.loadPersonalData();

    if (response.data) {
      personalData.value = response.data;
      permissions.value = createPermissionsFromRole(personalData.value?.role || null);

      personalData.value?.modulAccess?.forEach((moduleRole: string) => {
        permissions.value.push(moduleRole as Permission);
      });

      await loadTranslations();
    }
  };

  const saveSettings = async (settings: Settings) => {
    const response = await notifications.promise(commonApi.auth.saveDemoOfferVisibility(settings.demoOfferVisible), {
      text: 'auth.notification.saveSettings.success',
      errorText: 'auth.notification.saveSettings.error'
    });
    if (response.data && personalData.value) {
      personalData.value.demoOfferVisible = response.data.visible;
    }
  };

  const impersonate = async (tokens: AuthTokens) => {
    impersonated.value = true;
    setAuthTokens(tokens);
    await loadPersonalData();
  };

  const openFromOtherApp = async (tokens: AuthTokens) => {
    setAuthTokens(tokens);
    await loadPersonalData();
  };

  const hasPermission = (permission: Permission | Permission[]) => {
    return checkPermission(permissions.value, permission);
  };

  const getNewUserToken = async () => {
    const response = await commonApi.auth.getNewToken();

    return response.data;
  };

  const initializingPromise: Ref<Promise<void> | null> = ref(null);

  const initStore = async () => {
    try {
      if (accessToken.value) {
        await loadPersonalData();
      }
    } finally {
      initializingPromise.value = null;
    }
  };

  initializingPromise.value = initStore();

  return {
    accessToken,
    refreshToken,
    personalData,
    loggedIn,
    loginPromise,
    hasAnyModules,
    isDemoOfferVisible,
    isAdmin,
    login,
    logout,
    refreshAccessToken,
    forgotPassword,
    setPassword,
    changePassword,
    savePersonalData,
    saveSettings,
    impersonate,
    isImpersonated,
    hasPermission,
    permissions,
    initializingPromise,
    loadPersonalData,
    openFromOtherApp,
    getNewUserToken
  };
});
